$(() => {
  /***
   * URLに#がある場合の処理
   */
  $(window).on('load', function () {
    if (window.location.hash) {
      var hh
      if (window.matchMedia('(max-width: 1200px)').matches) {
        hh = $('.header__gnav').height()
      } else {
        hh = $('.header__head').height()
      }
      var h = hh ? hh : 0

      smoothScroll($(window.location.hash), 1, h)
      return false
    }
  })

  // 追従メニュー
  $(window).on('load', function () {

    var ht = $('.header__head').offset().top + $('.header__head').outerHeight()
    var hb = $('.header__gnav').height()

    $(window).on('resize', function () {
      if (!window.matchMedia('(max-width: 1200px)').matches) {
        ht = $('.header__head').offset().top + $('.header__head').outerHeight()
      }
    })

    var sp = $(this).scrollTop()

    if (!window.matchMedia('(max-width: 1200px)').matches) {
      // 初期読み込み時
      if (ht < sp) {
        $('.header__gnav').addClass('fixing')
        $('.header').css({'padding-bottom': hb,})
      }
    }

    $(this).on('scroll', function () {
      sp = $(this).scrollTop()

      // ページ上から下にスクロールした際、gnavの上を超えた時
      if (ht < sp) {
        $('.header__gnav').addClass('fixing')
        if (!window.matchMedia('(max-width: 1200px)').matches) {
          $('.header').css({'padding-bottom': hb,})
        }
      }

      // ページ下から上にスクロールした際、gnavの上を超えた時
      else {
        $('.header__gnav').removeClass('fixing')
        $('.header').attr('style', '')

        // スクロール後のanimation reset
        $('.header__gnavItem--entry a').attr('style', '')
      }
    })
  })

  // ボタンのアニメーションここから
  animeationInterval()

  function animeationInterval() {
    setInterval(
      function () {
        if ($('.header__gnav').hasClass('fixing')) {
          $('.fixing .header__gnavItem--entry a').css({'animation': 'pulse .5s ease 0s'})
          $('.header__gnavItem--entry a').on('webkitAnimationEnd animationend', function () {
            if ($('.header__gnav').hasClass('fixing')) {
              $(this).css({'animation': 'none'})
            }
          })
        }
      },
      10000
    )
  }

  // ボタンのアニメーションここまで


  // SP Menu Button
  $('.spMenu').on('click', function () {
    $(this).toggleClass('open')
    $('.header__spNav').toggleClass('open')
    return false
  })

  $(window).on('resize', function () {
    $('.spMenu').removeClass('open')
    $('.header__spNav').removeClass('open')
    $('.header__spNav__item--toggle a').removeClass('open')
    // $('.header__spNav__item--toggle a').removeClass('open')
    $('.header__spNav__innerList').slideUp()
  })

  // body click時に閉じる処理
  $('body').on('click', function () {
    $('.spMenu').removeClass('open')
    $('.header__spNav').removeClass('open')
    $('.header__spNav__item--toggle a').removeClass('open')
    // $('.header__spNav__item--toggle a').removeClass('open')
    $('.header__spNav__item--about a').removeClass('open')
    $('.header__spNav__innerList').slideUp()
  })

  // バブリング解消
  $('.spMenu, .header__spNav').on('click', function (e) {
    e.stopPropagation()
  })
  // SP Menu Button End

  $('.header__spNav__item--toggle .header__spNav__item--contentLink').on('click', function () {
    $(this).toggleClass('open')
    $(this).next().stop().slideToggle()
    return false
  })

  $('.header__spNav__item--about .header__spNav__item--aboutLink').on('click', function () {
    $(this).toggleClass('open')
    $(this).next().stop().slideToggle()
    return false
  })

  /*---------------------*\
   |                     |
   |    Set Functions    |
   |                     |
  \*---------------------*/

  function smoothScroll($target, $speed, $position) {
    $speed = $speed ? $speed : 500
    $position = $position ? $position : 0

    // アンカーの値取得
    var href = $target.attr("href")

    // hrefが取得できた場合
    if (href !== undefined) {
      href = href.match('#') ? '#' + href.split('#')[1] : ''
    }
    // hrefが取得できた場合
    else {
      href = '#' + $target.attr("id")
    }

    // 移動先を取得
    var target = $(href === "#" || href === "" ? 'html' : href)

    // 移動先を数値で取得
    var offsetTop = target.offset().top
    var position = offsetTop - Math.floor($position)

    // スクロールの速度
    var speed = $speed // ミリ秒

    // スムーススクロール
    $('body,html').animate({scrollTop: position}, speed, 'swing')
  }
})
